<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <UserToolbar
      v-if="account && Object.keys(account).length !== 0"
      :account="account"
    />
    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :finish-button-text="$t('form.save')"
      :back-button-text="$t('form.previous')"
      :next-button-text="$t('form.next')"
      class="steps-transparent mb-3"
      @on-complete="siteCreate"
      @on-change="validationStep"
    >
      <tab-content
        :title="$t('Main settings')"
        icon="feather icon-settings"
        :before-change="validationForm"
      >
        <validation-observer
          ref="validationSiteRules"
          tag="form"
        >
          <div class="head-card">
            <div class="head-card-top">
              <h4>{{ $t('Main settings') }}</h4>
              <feather-icon
                v-b-tooltip.hover.top="$t('Close')"
                icon="XIcon"
                size="18"
                class="toggle-close cursor-pointer"
                @click="$router.go(-1)"
              />
            </div>
            <hr>
          </div>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('sites.sitetitle')"
                label-for="i-title"
              >
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length == 0 || 'is-invalid'"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LayoutIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-title"
                      v-model="site.title"
                      :placeholder="$t('sites.sitetitle')"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="title"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('sites.sitedomain')"
                label-for="i-domain"
              >
                <validation-provider
                  #default="{ errors }"
                  name="domain"
                  :rules="{ required: true, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/ }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length == 0 || 'is-invalid'"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="GlobeIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-domain"
                      v-model="site.domain"
                      placeholder="https://domain.com"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="domain"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-group
                  :label="$t('sites.category')"
                  label-for="i-category"
                  :class="errors.length == 0 || 'is-invalid'"
                >
                  <b-form-select
                    v-model="site.category"
                    :options="[{ value: null, text: $t('sites.choosecategory'), disabled: true }, ...categories]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <b-form-group
                  :label="$t('sites.country')"
                  label-for="i-country"
                  :class="errors.length == 0 || 'is-invalid'"
                >
                  <v-select
                    id="i-country"
                    v-model="country"
                    :placeholder="$t('sites.choosecountry')"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="target_countries"
                    label="text"
                    @input="setSiteValue('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-form-group
                :label="$t('sites.advertorial_url')"
                label-for="i-advertorial_url"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="i-advertorial_url"
                    v-model="site.advertorial_url"
                    placeholder="https://domain.com/advertorial"
                    autocomplete="advertorial"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('sites.language')"
                label-for="i-language"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Language"
                  rules="required"
                >
                  <v-select
                    id="i-language"
                    v-model="language"
                    :placeholder="$t('sites.chooselanguage')"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="languages"
                    label="text"
                    @input="setSiteValue('language')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="authUserData.role.includes('admin')"
                :label="`${$t('sites.blacklist')} (${$t('sites.blacklist-helptext')})`"
                label-for="i-stop_words"
              >
                <validation-provider
                  #default="{ errors }"
                  name="stop_words"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length == 0 || 'is-invalid'"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="AlertTriangleIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-stop_words"
                      v-model="site.stop_words"
                      :placeholder="$t('sites.blacklist')"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="stop_words"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                v-if="authUserData.role.includes('admin')"
                label="Utm"
                label-for="i-utm"
              >
                <validation-provider
                  #default="{ errors }"
                  name="utm"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length == 0 || 'is-invalid'"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="TagIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-utm"
                      v-model="site.utm"
                      placeholder="?utm_source=phoenix"
                      :state="errors.length > 0 ? false:null"
                      autocomplete="utm"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('sites.status')"
                label-for="i-status"
              >
                <b-form-select
                  v-model="site.status"
                  :options="statusesTranslate"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content
        v-if="authUserData.role.includes('admin')"
        :title="$t('sites.adminsettings')"
        icon="feather icon-shield"
        :before-change="validationForm"
      >
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('sites.adminsettings') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-checkbox
                v-model="site.is_moderate"
              >
                {{ $t('sites.moderated') }}
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              :label="$t('sites.materialslifetime')"
              label-for="i-article_ttl"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-article_ttl"
                  v-model="site.article_ttl"
                  placeholder="7"
                  autocomplete="article_ttl"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              :label="$t('sites.timezone')"
              label-for="i-timezone"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="ClockIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-timezone"
                  v-model.number="site.timezone"
                  placeholder="-3"
                  autocomplete="timezone"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              :label="$t('sites.payment_method')"
              label-for="i-payment"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="FileTextIcon" />
                </b-input-group-prepend>
                <b-form-textarea
                  id="i-payment"
                  v-model="site.payment_method"
                  :placeholder="$t('sites.payment_method')"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              :label="$t('page.features.impressionRate')"
              label-for="i-impression_size_alert"
            >
              <validation-provider
                #default="{ errors }"
                name="impression_size_alert"
                rules="integer"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length == 0 || 'is-invalid'"
                >
                  <b-input-group-prepend is-text>
                    <feather-icon icon="BarChart2Icon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="i-impression_size_alert"
                    v-model="site.impression_size_alert"
                    type="number"
                    placeholder="500"
                    :state="errors.length > 0 ? false:null"
                    autocomplete="impression_size_alert"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              :label="$t('sites.tags')"
              label-for="i-tags"
            >
              <b-input-group
                class="input-group-merge"
              >
                <b-input-group-prepend is-text>
                  <feather-icon icon="TagIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-tag"
                  v-model.number="site.tags"
                  :placeholder="$t('sites.tags')"
                  autocomplete="tags"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                v-model="site.advanced_stats_enabled"
                :selected="site.advanced_stats_enabled"
              >
                {{ $t('sites.advanced_stats_enabled') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <h4 class="mb-2">
              {{ $t('sites.affiliatesettings') }}
            </h4>
            <b-form-group
              :label="$t('sites.affiliateuserid')"
              label-for="i-affiliate_user_id"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HashIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-affiliate_user_id"
                  v-model="site.affiliate_user_id"
                  placeholder="579205c94f32fe3c87f43719"
                  autocomplete="affiliate_user_id"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group
              :label="$t('sites.affiliateearn')"
              label-for="i-affiliate_earn_percent"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="PercentIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-affiliate_earn_percent"
                  v-model="site.affiliate_earn_percent"
                  placeholder="10"
                  autocomplete="affiliate_earn_percent"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              :label="$t('sites.agency_rate')"
              label-for="i-agency_rate"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="PercentIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="i-agency_rate"
                  v-model="site.agency_rate"
                  placeholder="10"
                  autocomplete="agency_rate"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>
      <tab-content
        v-if="authUserData.role.includes('admin')"
        ref="tabrss"
        :before-change="validationForm"
        title="RSS"
        icon="feather icon-rss"
      >
        <validation-observer
          ref="validationSiteRules"
          tag="form"
        >
          <div class="head-card">
            <div class="head-card-top">
              <h4>RSS</h4>
              <feather-icon
                v-b-tooltip.hover.top="$t('Close')"
                icon="XIcon"
                size="18"
                class="toggle-close cursor-pointer"
                @click="$router.go(-1)"
              />
            </div>
            <hr>
          </div>
          <b-row>
            <b-col md="12">
              <b-form-group>
                <b-form-checkbox
                  v-model="site.rss_enabled"
                  :selected="site.rss_enabled"
                >
                  {{ $t('form.enable') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="site.rss_enabled">
            <b-row>
              <b-col sm="12">
                <b-form-group
                  :label="$t('sites.rss_version')"
                  label-for="i-rss_version"
                >
                  <b-form-select
                    v-model="site.rss_version"
                    :options="rss_versions"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('sites.date_parse_string')"
                  label-for="i-date_parse_string"
                >
                  <b-form-select
                    v-model="date_parse_format_selected"
                    class="mb-25"
                    :options="dateParseFormatKeys"
                  />
                  <template v-if="date_parse_format_selected !== 'Custom'">
                    <div class="alert alert-info">
                      <div class="alert-body">
                        {{ site.date_parse_string }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <b-form-input
                      v-model="date_parse_string_custom"
                      placeholder="Enter custom format"
                    />
                  </template>
                </b-form-group>
                <b-form-group
                  :label="$t('sites.rss_url')"
                  label-for="i-rss_url"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('sites.rss_url')"
                    :rules="`${site.rss_enabled ? 'required' : ''}`"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length == 0 || 'is-invalid'"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-rss_url"
                        v-model="site.rss_url"
                        placeholder="https://domain.com/rss.xml"
                        autocomplete="rss"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="checkRSS"
                >
                  <span class="align-middle">{{ $t('Check') }}</span>
                  <feather-icon
                    icon="ChevronRightIcon"
                    class="ml-50"
                  />
                </b-button>
              </b-col>
            </b-row>
          </template>
        </validation-observer>
      </tab-content>
      <tab-content
        v-if="authUserData.role.includes('admin')"
        ref="tabparser"
        :before-change="parserValidation"
        :title="$t('sites.parsing')"
        icon="feather icon-cpu"
      >
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('sites.parsing') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox
                v-model="site.enable_site_content_parsing"
                :selected="site.enable_site_content_parsing"
              >
                {{ $t('form.turnon') }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <template v-if="site.enable_site_content_parsing">
          <b-row>
            <b-col md="12">
              <SiteUserParsing />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <h4 class="mb-2">
                {{ $t('sites.params') }}
              </h4>

              <b-form-group class="mt-1">
                <b-form-checkbox
                  v-model="parserDefault"
                  value="1"
                  @change="setParserDefault()"
                >
                  {{ $t('form.default_settings') }}
                </b-form-checkbox>
              </b-form-group>

              <template
                v-if="!parserDefault.length"
              >
                <b-form-group
                  :label="$t('sites.encoding')"
                  label-for="i-encoding"
                >
                  <b-form-select
                    v-model="encoding"
                    :options="encodings"
                  />
                </b-form-group>

                <b-form-group
                  :label="$t('sites.urlmask')"
                  label-for="i-url_mask"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <span>[.*]</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-url_mask"
                      v-model="site.url_mask"
                      placeholder="regexp"
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :label="$t('sites.category_mask')"
                  label-for="i-category_mask"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <span>[.*]</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="i-category_mask"
                      v-model="site.category_mask"
                      placeholder="regexp"
                    />
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  :label="$t('sites.parser')"
                  label-for="i-parser"
                >
                  <b-form-select
                    v-model="site.parser.title"
                    :options="parser"
                  />
                </b-form-group>
                <template v-if="site.parser.title === 'core.parser.processor.pquery.parser.PyQueryParser'">
                  <b-form-group
                    :label="$t('sites.title_mask')"
                    label-for="i-title_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-title_mask"
                        v-model="title_mask"
                        :value="parserFieldValueByKey('title_mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.parser.fields, 'title_mask')"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    :label="$t('sites.image_mask')"
                    label-for="i-image_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-image_mask"
                        v-model="image_mask"
                        :value="parserFieldValueByKey('image_mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.parser.fields, 'image_mask')"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    :label="$t('sites.image_prefix')"
                    label-for="i-image_prefix"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-image_prefix"
                        v-model="image_prefix"
                        :value="parserFieldValueByKey('image_prefix')"
                        placeholder=""
                        @input="setParserFieldByKey(site.parser.fields, 'image_prefix')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="site.parser.title === 'core.parser.processor.rdability.parser.ReadabilityParser'">
                  <b-form-group
                    :label="$t('sites.image_prefix')"
                    label-for="i-image_prefix"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-image_prefix"
                        v-model="image_prefix"
                        :value="parserFieldValueByKey('image_prefix')"
                        placeholder=""
                        @input="setParserFieldByKey(site.parser.fields, 'image_prefix')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>

                <b-form-group
                  :label="$t('sites.date')"
                  label-for="i-date_parser"
                >
                  <b-form-select
                    v-model="site.date_parser.title"
                    :options="date_parser"
                  />
                </b-form-group>
                <template v-if="site.date_parser.title === 'core.parser.processor.date.parser.DefaultDateParser'">
                  <b-form-group
                    :label="$t('sites.date_mask')"
                    label-for="i-date_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-date_mask"
                        v-model="mask"
                        :value="dateParserFieldValueByKey('mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.UrlRegexDateParser'">
                  <b-form-group
                    :label="$t('sites.date_mask')"
                    label-for="i-date_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-date_mask"
                        v-model="mask"
                        :value="dateParserFieldValueByKey('mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                      />
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    :label="$t('sites.regex')"
                    label-for="i-date_regex"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-date_regex"
                        v-model="regex"
                        :value="dateParserFieldValueByKey('regex')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'regex')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.PyQueryDateParser'">
                  <b-form-group
                    :label="$t('sites.date_mask')"
                    label-for="i-date_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-date_mask"
                        v-model="mask"
                        :value="dateParserFieldValueByKey('mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'mask')"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('sites.html_selector')"
                    label-for="i-date_mask2"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-date_mask2"
                        v-model="date_mask"
                        :value="dateParserFieldValueByKey('date_mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'date_mask')"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('sites.tag_attr')"
                    label-for="i-tag_attr"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-tag_attr"
                        v-model="tag_attr"
                        :value="dateParserFieldValueByKey('tag_attr')"
                        placeholder=""
                        @input="setParserFieldByKey(site.date_parser.fields, 'tag_attr')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>

                <b-form-group
                  :label="$t('sites.language')"
                  label-for="i-language_parser"
                >
                  <b-form-select
                    v-model="site.language_parser.title"
                    :options="language_parser"
                  />
                </b-form-group>
                <template v-if="site.language_parser.title === 'core.parser.processor.language.parser.UrlRegexLanguageParser'">
                  <b-form-group
                    :label="$t('sites.default_language')"
                    label-for="i-default_language"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-default_language"
                        v-model="default_language"
                        :value="languageParserFieldValueByKey('default_language')"
                        placeholder=""
                        @input="setParserFieldByKey(site.language_parser.fields, 'default_language')"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('sites.regex')"
                    label-for="i-language_regex"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-language_regex"
                        v-model="language_regex"
                        :value="languageParserFieldValueByKey('language_regex')"
                        placeholder=""
                        @input="setParserFieldByKey(site.language_parser.fields, 'language_regex')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="site.language_parser.title === 'core.parser.processor.language.parser.PyQueryLanguageParser'">
                  <b-form-group
                    :label="$t('sites.default_language')"
                    label-for="i-default_language"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-default_language"
                        v-model="default_language"
                        :value="languageParserFieldValueByKey('default_language')"
                        placeholder=""
                        @input="setParserFieldByKey(site.language_parser.fields, 'default_language')"
                      />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group
                    :label="$t('sites.html_selector')"
                    label-for="i-language_mask"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <span>[.*]</span>
                      </b-input-group-prepend>
                      <b-form-input
                        id="i-language_mask"
                        v-model="language_mask"
                        :value="languageParserFieldValueByKey('language_mask')"
                        placeholder=""
                        @input="setParserFieldByKey(site.language_parser.fields, 'language_mask')"
                      />
                    </b-input-group>
                  </b-form-group>
                </template>
              </template>
            </b-col>
            <b-col md="6">
              <h4 class="mb-2">
                {{ $t('sites.testing') }}
              </h4>
              <b-form-group
                :label="$t('sites.urlcheck')"
                label-for="i-checking"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="Link2Icon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="i-checking"
                    v-model="checking"
                    :placeholder="$t('sites.urlcheck')"
                    autocomplete="checking"
                  />
                  <b-input-group-append>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="checkUrl"
                    >
                      <span class="align-middle">{{ $t('Check') }}</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                      />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <div
                v-for="(result, idx) in Object.keys(checkResult)"
                :key="idx"
              >
                <div
                  v-if="checkResult[result] !== undefined && checkResult[result] !== null && Object.keys(checkResult[result]).length !== 0"
                  class="ibox mt-2"
                  :class="siteTest ? result : 'warning'"
                >
                  <div class="header">
                    <feather-icon
                      icon="AlertCircleIcon"
                      width="24"
                      height="24"
                    />
                    <strong class="mr-auto">{{ siteTest ? result : 'warning' }}</strong>
                  </div>
                  <div class="body">
                    <div
                      class="mb-1"
                      :class="{'text-danger': !checkResult[result].image }"
                    >
                      <strong class="mr-25">Image:</strong>
                      <b-img
                        v-if="checkResult[result].image"
                        :src="checkResult[result].image"
                        :alt="checkResult[result].title"
                        fluid
                      />
                      <feather-icon
                        v-if="!checkResult[result].image"
                        icon="XIcon"
                        size="16"
                        class="text-danger"
                      />
                    </div>
                    <div
                      class="mb-1"
                      :class="{'text-danger': !checkResult[result].title }"
                    >
                      <strong>Title:</strong> {{ checkResult[result].title }}
                      <feather-icon
                        v-if="!checkResult[result].title"
                        icon="XIcon"
                        size="16"
                        class="text-danger"
                      />
                    </div>
                    <div
                      :class="{'text-danger': !checkResult[result].description }"
                    >
                      <strong>Description:</strong> {{ checkResult[result].description }}
                      <feather-icon
                        v-if="!checkResult[result].description"
                        icon="XIcon"
                        size="16"
                        class="text-danger"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </tab-content>
      <tab-content
        :before-change="parserValidation"
        :title="$t('sites.summary')"
        icon="feather icon-check"
      >
        <div class="head-card">
          <div class="head-card-top">
            <h4>{{ $t('sites.summary') }}</h4>
            <feather-icon
              v-b-tooltip.hover.top="$t('Close')"
              icon="XIcon"
              size="18"
              class="toggle-close cursor-pointer"
              @click="$router.go(-1)"
            />
          </div>
          <hr>
        </div>
        <b-row>
          <b-col md="6">
            <h4 class="mb-2">
              {{ $t('Main settings') }}
            </h4>
            <div class="list-striped">
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.sitetitle') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.title }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.sitedomain') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.domain }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.category') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.category ? $t(`selects.categories.${site.category}`) : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.country') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ country ? country.text : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.language') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ language ? $t(language.value) : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.blacklist') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.stop_words ? site.stop_words : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  Utm
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.utm ? site.utm : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.status') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ $t(`selects.statuses.${site.status}`) }}
                </dd>
              </dl>
            </div>
          </b-col>
          <b-col
            v-if="authUserData.role.includes('admin')"
            md="6"
          >
            <h4 class="mb-2">
              RSS
            </h4>
            <dl class="row">
              <dt class="col-sm-6">
                {{ $t('form.turnon') }}
              </dt>
              <dd class="col-sm-6 text-right">
                {{ site.rss_enabled ? $t('sites.yes') : $t('sites.no') }}
              </dd>
            </dl>
            <template v-if="site.rss_enabled">
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.rss_version') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.rss_version ? rss_versions.find(v => v.value === site.rss_version).text : '-' }}
                </dd>
              </dl>
              <dl class="row mb-2">
                <dt class="col-sm-6">
                  {{ $t('sites.date_parse_string') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.date_parse_string ? site.date_parse_string : '-' }}
                </dd>
              </dl>
              <dl class="row mb-2">
                <dt class="col-sm-6">
                  {{ $t('sites.rss_url') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.rss_url }}
                </dd>
              </dl>
            </template>

            <h4 class="mb-2">
              {{ $t('sites.parsing') }}
            </h4>
            <div class="list-striped">
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('form.default_settings') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ parserDefault.length !== 0 ? $t('sites.yes') : $t('sites.no') }}
                </dd>
              </dl>
              <template
                v-if="!parserDefault.length"
              >
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.encoding') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ encoding }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.urlmask') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ site.url_mask ? site.url_mask : '-' }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.category_mask') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ site.category_mask ? site.category_mask : '-' }}
                  </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.parser') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ site.parser.title ? nameByValue(parser, site.parser.title) : '-' }}
                  </dd>
                </dl>
                <template v-if="site.parser.title === 'core.parser.processor.pquery.parser.PyQueryParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.title_mask') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ parserFieldValueByKey('title_mask') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.image_mask') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ parserFieldValueByKey('image_mask') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.image_prefix') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ parserFieldValueByKey('image_prefix') }}
                    </dd>
                  </dl>
                </template>
                <template v-else-if="site.parser.title === 'core.parser.processor.rdability.parser.ReadabilityParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.image_prefix') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ parserFieldValueByKey('image_prefix') }}
                    </dd>
                  </dl>
                </template>
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.date') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ site.date_parser.title ? nameByValue(date_parser, site.date_parser.title) : '-' }}
                  </dd>
                </dl>
                <template v-if="site.date_parser.title === 'core.parser.processor.date.parser.DefaultDateParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.date_mask') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('mask') }}
                    </dd>
                  </dl>
                </template>
                <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.UrlRegexDateParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.date_mask') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('mask') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.regex') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('regex') }}
                    </dd>
                  </dl>
                </template>
                <template v-else-if="site.date_parser.title === 'core.parser.processor.date.parser.PyQueryDateParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.date_mask') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('mask') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.html_selector') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('date_mask') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.tag_attr') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ dateParserFieldValueByKey('tag_attr') }}
                    </dd>
                  </dl>
                </template>
                <dl class="row">
                  <dt class="col-sm-6">
                    {{ $t('sites.language') }}
                  </dt>
                  <dd class="col-sm-6 text-right">
                    {{ site.language_parser.title ? nameByValue(language_parser, site.language_parser.title) : '-' }}
                  </dd>
                </dl>
                <template v-if="site.language_parser.title === 'core.parser.processor.language.parser.UrlRegexLanguageParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.default_language') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ languageParserFieldValueByKey('default_language') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.regex') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ languageParserFieldValueByKey('language_regex') }}
                    </dd>
                  </dl>
                </template>
                <template v-else-if="site.language_parser.title === 'core.parser.processor.language.parser.PyQueryLanguageParser'">
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.default_language') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ languageParserFieldValueByKey('default_language') }}
                    </dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-sm-6">
                      {{ $t('sites.html_selector') }}
                    </dt>
                    <dd class="col-sm-6 text-right">
                      {{ languageParserFieldValueByKey('language_mask') }}
                    </dd>
                  </dl>
                </template>
              </template>
            </div>
          </b-col>
          <b-col md="6">
            <h4 class="mb-2">
              {{ $t('sites.adminsettings') }}
            </h4>
            <div class="list-striped">
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.moderated') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.is_moderate.length !== 0 ? $t('sites.yes') : $t('sites.no') }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.materialslifetime') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.article_ttl }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.timezone') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.timezone }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.payment_method') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.payment_method ? site.payment_method : '-' }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('page.features.impressionRate') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.impression_size_alert }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.affiliateuserid') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.affiliate_user_id }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.affiliateearn') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.affiliate_earn_percent }}
                </dd>
              </dl>
              <dl class="row">
                <dt class="col-sm-6">
                  {{ $t('sites.agency_rate') }}
                </dt>
                <dd class="col-sm-6 text-right">
                  {{ site.agency_rate }}
                </dd>
              </dl>
            </div>
          </b-col>
        </b-row>
      </tab-content>
      <template
        slot="footer"
        slot-scope="props"
      >
        <div
          class="wizard-footer-left"
        >
          <wizard-button
            v-if="props.activeTabIndex > 0"
            :style="props.fillButtonStyle"
            @click.native="props.prevTab()"
          >
            {{ $t('form.previous') }}
          </wizard-button>
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="disabledNext && !skipedStep"
            class="wizard-footer-left mr-1"
            :style="props.fillButtonStyle"
            @click.native="skipStep()"
          >
            {{ $t('form.skip') }}
          </wizard-button>
          <wizard-button
            v-if="!props.isLastStep"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            :disabled="disabledNext"
            @click.native="props.nextTab()"
          >
            {{ $t('form.next') }}
          </wizard-button>
          <wizard-button
            v-else
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            @click.native="siteCreate()"
          >
            {{ props.isLastStep ? $t('form.save') : $t('form.next') }}
          </wizard-button>
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import {
  BRow,
  BCol,
  BFormGroup,
  BImg,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import { FormWizard, WizardButton, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, integer, min } from '@validations'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserToolbar from '@/views/dashboard/users/UserToolbar.vue'
import { getUserData } from '@/auth/utils'
import SiteUserParsing from './SiteUserParsing.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    BRow,
    BCol,
    Breadcrumbs,
    BFormGroup,
    BImg,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BButton,
    FormWizard,
    TabContent,
    WizardButton,
    SiteUserParsing,
    UserToolbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      visible: true,
      pageTitle: 'Create the site',
      account: {},
      min,
      required,
      integer,
      checking: '',
      site: {
        user_id: this.$route.params.userId,
        title: '',
        domain: '',
        advertorial_url: '',
        country: null,
        language: '',
        stop_words: null,
        status: 'active',
        category: null,
        is_moderate: false,
        rss_enabled: false,
        rss_ur: '',
        enable_site_content_parsing: false,
        tags: '',
        advanced_stats_enabled: false,
        parser: {
          title: 'core.parser.processor.opengraph.parser.OpenGraphParser',
          fields: [],
        },
        created_at: null,
        encoding: 'auto',
        date_parser: {
          title: 'core.parser.processor.date.parser.DefaultDateParser',
          fields: [
            {
              key: 'mask',
              value: '%d %m %y',
            },
          ],
        },
        articles: null,
        article_ttl: 3,
        category_mask: null,
        timezone: 0,
        language_parser: {
          title: 'core.parser.processor.language.parser.DefaultLanguageParser',
          fields: [],
        },
        shows: 0,
        utm: null,
        payment_method: null,
        impression_size_alert: 500,
        affiliate_user_id: null,
        affiliate_earn_percent: null,
        agency_rate: 0,
        date_parse_string: '',
      },
      breadcrumbItems: [],
      category: null,
      country: null,
      language: null,
      encoding: 'auto',
      ...options,
      authUserData: getUserData(),
      parserDefault: [1],
      checkResult: {
        success: {},
        error: {},
      },
      disabledNext: false,
      skipedStep: false,
      siteTest: true,
      title_mask: '',
      image_mask: '',
      image_prefix: '',
      mask: '',
      regex: '',
      date_mask: '',
      tag_attr: '',
      language_regex: '',
      default_language: '',
      language_mask: '',
      date_parse_formats: {
        Layout: '01/02 03:04:05PM \'06 -0700',
        ANSIC: 'Mon Jan _2 15:04:05 2006',
        UnixDate: 'Mon Jan _2 15:04:05 MST 2006',
        RubyDate: 'Mon Jan 02 15:04:05 -0700 2006',
        RFC822: '02 Jan 06 15:04 MST',
        RFC822Z: '02 Jan 06 15:04 -0700',
        RFC850: 'Monday, 02-Jan-06 15:04:05 MST',
        RFC1123: 'Mon, 02 Jan 2006 15:04:05 MST',
        RFC1123Z: 'Mon, 02 Jan 2006 15:04:05 -0700',
        RFC3339: '2006-01-02T15:04:05Z07:00',
        RFC3339Nano: '2006-01-02T15:04:05.999999999Z07:00',
        Kitchen: '3:04PM',
        Stamp: 'Jan _2 15:04:05',
        StampMilli: 'Jan _2 15:04:05.000',
        StampMicro: 'Jan _2 15:04:05.000000',
        StampNano: 'Jan _2 15:04:05.000000000',
        DateTime: '2006-01-02 15:04:05',
        DateOnly: '2006-01-02',
        TimeOnly: '15:04:05',
        Custom: null,
      },
      date_parse_format_selected: 'Layout',
      date_parse_string_custom: '',
    }
  },
  computed: {
    dateParseFormatKeys() {
      return Object.keys(this.date_parse_formats)
    },
    statusesTranslate() {
      return this.statuses.map(s => {
        const item = {}
        item.value = s.value
        item.text = this.$t(`selects.statuses.${s.value}`)
        return item
      })
    },
  },
  watch: {
    date_parse_format_selected: {
      handler(newVal) {
        if (newVal !== 'Custom') {
          this.site.date_parse_string = this.date_parse_formats[newVal]
        }
      },
    },
    date_parse_string_custom() {
      this.site.date_parse_string = this.date_parse_string_custom
    },
  },
  created() {
    this.getUserData()
    if (this.date_parse_format_selected === 'Custom') {
      this.site.date_parse_string = this.date_parse_string_custom
    } else {
      this.site.date_parse_string = this.date_parse_formats[this.date_parse_format_selected]
    }
  },
  methods: {
    setSiteValue(key) {
      if (key === 'country') {
        this.site.country = this.country.value
      } else if (key === 'language') {
        this.site.language = this.language.value
      }
    },
    async getUserData() {
      const responseData = await useJwt.getUser(this.$route.params.userId)
      this.account = responseData.data.account || {}
      this.setBreabcrumbs()
    },
    parserFieldValueByKey(key) {
      const idx = this.site.parser.fields.findIndex(i => i.key === key)
      return this.site.parser.fields[idx] !== undefined ? this.site.parser.fields[idx].value : ''
    },
    dateParserFieldValueByKey(key) {
      const idx = this.site.date_parser.fields.findIndex(i => i.key === key)
      return this.site.date_parser.fields[idx] !== undefined ? this.site.date_parser.fields[idx].value : ''
    },
    languageParserFieldValueByKey(key) {
      const idx = this.site.language_parser.fields.findIndex(i => i.key === key)
      return this.site.language_parser.fields[idx] !== undefined ? this.site.language_parser.fields[idx].value : ''
    },
    setParserFieldByKey(arrayFields, key) {
      const idx = arrayFields.findIndex(i => i.key === key)
      if (idx === -1) {
        arrayFields.push({ key, value: this[key] })
      } else {
        arrayFields[idx] = { key, value: this[key] }
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.validationSiteRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    skipStep() {
      this.disabledNext = false
      this.skipedStep = true
    },
    validationStep(_, newIndex) {
      this.currentTab = newIndex
      if (this.skipedStep === false) {
        this.disabledNext = false
        if (this.authUserData.role.includes('admin')) {
          if (newIndex === 3) {
            this.disabledNext = true
            this.skipedStep = false
          }
        }

        if (!this.authUserData.role.includes('admin')) {
          if (newIndex === 2) {
            this.disabledNext = true
            this.skipedStep = false
          }
        }
      }
    },
    parserValidation() {
      return new Promise((resolve, reject) => {
        const success = true
        if (success) {
          resolve(true)
        } else {
          reject()
        }
      })
    },
    siteCreate() {
      this.$refs.validationSiteRules.validate().then(success => {
        if (success) {
          this.site.language = this.language.value
          this.site.country = this.country.value
          this.site.encoding = this.encoding
          this.site.timezone = this.site.timezone || 0

          if (this.site.rss_enabled) {
            if (this.date_parse_format_selected === 'Custom') {
              this.site.date_parse_string = this.date_parse_string_custom
            } else {
              this.site.date_parse_string = this.date_parse_formats[this.date_parse_format_selected]
            }
          }

          useJwt.createSite({ site: this.site }).then(result => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Site saved',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push(`/users/${this.$route.params.userId}/sites/${result.data.site._id}/widgets/create`)
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: err.detail,
                    },
                  })
                  const field = err.source.pointer.split('/')
                  this.$refs.validationSiteRules.setErrors({ [field[field.length - 1]]: err.detail })
                })
              }
            }
          })
        }
      })
    },
    checkRSS() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Check RSS',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    },
    async checkUrl() {
      const parserData = {
        url: this.checking,
        encoding: this.site.encoding,
        category_mask: this.site.category_mask,
        parser: this.site.parser,
        dateParser: this.site.date_parser,
        languageParser: this.site.language_parser,
      }
      await useJwt.testSiteParser(parserData).then(result => {
        this.checkResult.success = result.data
        if (Object.keys(result.data).length !== 0) {
          this.disabledNext = false
          Object.keys(result.data).forEach(key => {
            if (key === 'title' || key === 'image' || key === 'description') {
              if (result.data[key] === null) {
                this.disabledNext = true
                this.skipedStep = false
                this.siteTest = false
                this.$refs.wizard.tabs[3].checked = false
              }
            }
          })
        }
      }).catch(error => {
        this.checkResult.error = error.data
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    setParserDefault() {
      if (this.parserDefault.includes('1')) {
        this.site.parser.title = 'core.parser.processor.opengraph.parser.OpenGraphParser'
      }
    },
    nameByValue(arr, value) {
      const name = arr.filter(i => i.value === value)
      return name[0] ? name[0].text : '-'
    },
    async setBreabcrumbs() {
      if (this.account.is_admin) {
        this.breadcrumbItems = [
          {
            text: this.$t('page.menu.users'),
            to: '/users',
          },
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: this.$t('page.menu.sites'),
            to: `/users/${this.$route.params.userId}/sites`,
          },
          {
            text: this.$t('page.sites.create'),
            active: true,
          },
        ]
      } else {
        this.breadcrumbItems = [
          {
            text: this.account.name,
            to: `/users/${this.$route.params.userId}`,
          },
          {
            text: this.$t('page.menu.sites'),
            to: `/users/${this.$route.params.userId}/sites`,
          },
          {
            text: this.$t('page.sites.create'),
            active: true,
          },
        ]
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
